<template>
  <section
    class="form-section"
    :aria-label="$t('utils.formSection', { value: $t('gen.planning') })"
  >
    <base-typology tag="h3">
      {{ $t('gen.planning') }}
    </base-typology>
    <aab-datepicker
      id="input-record-date"
      :left-label="$t('shareholderId.recordDate')"
      :message="recordDateValidationMessage"
      :message-type="recordDateValidationMessage ? 'negative' : null"
      :disabled-weekdays="JSON.stringify(disabledWeekDays)"
      :start-date="numericRecordDate ? numericRecordDate / 1000 : null"
      @aab-datepicker-date-from-changed="recordDateChanged($event)"
      @aab-datepicker-invalid-date-from="recordDateChanged($event)"
      @aab-datepicker-date-from-out-of-range="recordDateChanged($event)"
    />
    <div>
      <div class="mb-1">
        <base-typology tag="label">
          {{ $t('shareholderId.startDate') }}
        </base-typology>
      </div>
      <div class="radio-button-group">
        <aab-radio-button
          id="start-date-scheduled-radio"
          @click="form.startDateScheduled = true"
          @keyup.enter="form.startDateScheduled = true"
        >
          <input
            id="start-date-scheduled-input"
            slot="input-slot"
            name="start-date-scheduled"
            type="radio"
            :checked="form.startDateScheduled"
          >
          <label
            slot="label-slot"
            for="start-date-scheduled"
            class="text-center"
          >
            {{ $t('gen.scheduled') }}
          </label>
        </aab-radio-button>
        <aab-radio-button
          id="start-date-asap-radio"
          @click="onClickAsapStartDate"
          @keyup.enter="onClickAsapStartDate"
        >
          <input
            id="start-date-asap-input"
            slot="input-slot"
            name="start-date-asap"
            type="radio"
            :checked="!form.startDateScheduled"
          >
          <label
            slot="label-slot"
            for="start-date-asap"
            class="text-center"
          >
            {{ $t('gen.asap') }}
          </label>
        </aab-radio-button>
      </div>
      <div
        v-if="startDateHelperText"
        id="start-date-helper-text"
        class="mt-1"
      >
        <base-typology
          variant="small"
          color="gray"
        >
          {{ startDateHelperText }}
        </base-typology>
      </div>
    </div>
    <div v-if="form.startDateScheduled">
      <aab-datepicker
        id="input-start-date"
        :left-label="$t('utils.setValue', { field: $t('shareholderId.startDate').toLowerCase() })"
        :message="startDateValidationMessage"
        :message-type="startDateValidationMessage ? 'negative' : null"
        :disabled-weekdays="JSON.stringify(disabledWeekDays)"
        :start-date="numericStartDate / 1000"
        :min-selectable-date="minimumStartDate.getTime() / 1000"
        @aab-datepicker-date-from-changed="startDateChanged($event)"
        @aab-datepicker-invalid-date-from="startDateChanged($event)"
        @aab-datepicker-date-from-out-of-range="startDateChanged($event)"
      />
    </div>
    <div>
      <div class="mb-1">
        <base-typology tag="label">
          {{ $t('gen.deadline') }}
        </base-typology>
      </div>
      <div class="radio-button-group">
        <aab-radio-button
          id="deadline-standard-radio"
          @click="onClickStandardDeadlines"
          @keyup.enter="onClickStandardDeadlines"
        >
          <input
            id="deadline-standard-input"
            slot="input-slot"
            name="deadline-standard"
            type="radio"
            :checked="form.deadlineStandard"
          >
          <label
            slot="label-slot"
            for="deadline-standard"
            class="text-center"
          >
            {{ $t('gen.standard') }}
          </label>
        </aab-radio-button>
        <aab-radio-button
          id="deadline-custom-radio"
          @click="form.deadlineStandard = false"
          @keyup.enter="form.deadlineStandard = false"
        >
          <input
            id="deadline-custom-input"
            slot="input-slot"
            name="deadline-custom"
            type="radio"
            :checked="!form.deadlineStandard"
          >
          <label
            slot="label-slot"
            for="deadline-custom"
            class="text-center"
          >
            {{ $t('gen.custom') }}
          </label>
        </aab-radio-button>
      </div>
      <div
        v-if="deadlineHelperText"
        id="deadline-helper-text"
        class="mt-1"
      >
        <base-typology
          variant="small"
          color="gray"
        >
          {{ deadlineHelperText }}
        </base-typology>
      </div>
    </div>
    <div v-if="!form.deadlineStandard">
      <aab-datepicker
        id="input-disclosure-deadline"
        :left-label="$t(
          'utils.setValue', { field: $t('shareholderId.disclosureDeadlineDate').toLowerCase() })"
        :message="disclosureDeadlineValidationMessage"
        :message-type="disclosureDeadlineValidationMessage ? 'negative' : null"
        :disabled-weekdays="JSON.stringify(disabledWeekDays)"
        :start-date="numericDisclosureDeadline / 1000"
        :min-selectable-date="minimumDisclosureDeadline.getTime() / 1000"
        @aab-datepicker-date-from-changed="disclosureDeadlineChanged($event)"
        @aab-datepicker-date-from-out-of-range="disclosureDeadlineChanged($event)"
      />
    </div>
    <div v-if="!form.deadlineStandard">
      <aab-datepicker
        id="input-report-deadline"
        :left-label="$t(
          'utils.setValue', { field: $t('shareholderId.reportDeadlineDate').toLowerCase() })"
        :message="reportDeadlineValidationMessage"
        :message-type="reportDeadlineValidationMessage ? 'negative' : null"
        :disabled-weekdays="JSON.stringify(disabledWeekDays)"
        :start-date="numericReportDeadline / 1000"
        :min-selectable-date="minimumReportDeadline.getTime() / 1000"
        @aab-datepicker-date-from-changed="reportDeadlineChanged($event)"
        @aab-datepicker-date-from-out-of-range="reportDeadlineChanged($event)"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { dateShouldNotBeBefore, requiredSelect } from '@/utils/customValidation';
import { subtractWorkDaysToUnix, addWorkDaysToUnix } from '@/utils/date';
import createValidationMessage from '@/utils/validationUtils';

/**
 * FormPlanning is a section in the SID create form.
 * It deals with all the dates required for a SID request.
 * Record date sets the limit for start date.
 * Start date sets the limit for disclosure deadline.
 * Disclosure deadline sets the limit for report deadline.
 * The validation rules are all specified at the bottom.
 */
export default defineComponent({
  name: 'FormPlanning',
  components: { BaseTypology },
  data() {
    return {
      v$: useVuelidate(),
      disabledWeekDays: [0, 6],
      numericRecordDate: null,
      numericStartDate: null,
      numericDisclosureDeadline: null,
      numericReportDeadline: null,
      form: {
        recordDate: null,
        startDateScheduled: false,
        startDate: null,
        deadlineStandard: true,
        disclosureDeadline: null,
        reportDeadline: null,
      },
    };
  },
  computed: {
    recordDateValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'recordDate');
    },
    startDateValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'startDate');
    },
    disclosureDeadlineValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'disclosureDeadline');
    },
    reportDeadlineValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'reportDeadline');
    },
    startDateHelperText() {
      if (!this.form.recordDate && !this.form.startDateScheduled) {
        return this.$t('shareholderId.noRecordDate');
      }
      if (this.form.recordDate && !this.form.startDateScheduled) {
        return this.$t('shareholderId.possibleStartDate', { field: this.$d(this.form.startDate, 'short') });
      }
      return null;
    },
    deadlineHelperText() {
      if (!this.form.startDate && this.form.deadlineStandard) {
        return this.$t('shareholderId.noStartDate');
      }
      if (this.form.startDate && this.form.deadlineStandard) {
        return this.$t('shareholderId.deadlines', { disclosureDeadline: this.$d(this.form.disclosureDeadline, 'short'), reportDeadline: this.$d(this.form.reportDeadline, 'short') });
      }
      return null;
    },
    minimumStartDate() {
      // if record date is today, use tomorrow
      const today = new Date(Date.now());
      if ((this.numericRecordDate < today.getTime())
        && (this.numericRecordDate + 86400000 >= today.getTime())) {
        return this.tomorrow();
      }
      // if record date in the past, use today or next workday
      if (this.numericRecordDate < today.getTime()) {
        if (today.getDay() === 6 || today.getDay() === 0) { // Saturday or Sunday
          return addWorkDaysToUnix(today.getTime(), 1);
        }
        return today;
      }
      // otherwise, day after record date
      return addWorkDaysToUnix(this.numericRecordDate, 1);
    },
    minimumDisclosureDeadline() {
      if (this.numericStartDate) {
        return addWorkDaysToUnix(this.numericStartDate, 1);
      }
      return this.tomorrow();
    },
    minimumReportDeadline() {
      if (this.numericDisclosureDeadline) {
        return addWorkDaysToUnix(this.numericDisclosureDeadline, 1);
      }
      return this.tomorrow();
    },
  },
  methods: {
    tomorrow() {
      return addWorkDaysToUnix(new Date(Date.now()).getTime(), 1);
    },
    onClickAsapStartDate() {
      this.form.startDateScheduled = false;
      this.calculateStartDateDefaults();
    },
    onClickStandardDeadlines() {
      this.form.deadlineStandard = true;
      this.calculateStandardDeadlines();
    },
    // standard date getters for deadlines
    calculateStandardDeadlines() {
      this.form.disclosureDeadline = addWorkDaysToUnix(this.numericStartDate, 5);
      this.numericDisclosureDeadline = new Date(this.form.disclosureDeadline).getTime();
      this.form.reportDeadline = addWorkDaysToUnix(this.numericDisclosureDeadline, 5);
      this.numericReportDeadline = new Date(this.form.reportDeadline).getTime();
    },
    calculateStartDateDefaults() {
      if (!this.form.startDateScheduled && this.form.recordDate) {
        this.form.startDate = this.minimumStartDate;
        this.numericStartDate = new Date(this.form.startDate).getTime();
        if (this.form.deadlineStandard) {
          this.calculateStandardDeadlines();
        }
      }
    },
    recordDateChanged(event) {
      if (!event.detail.value) {
        this.form.recordDate = null;
        this.v$.form.recordDate.$touch();
        return;
      }
      // get date from unix
      this.numericRecordDate = event.detail.value * 1000;
      this.form.recordDate = new Date(this.numericRecordDate);
      // get defaults
      this.calculateStartDateDefaults();
    },
    startDateChanged(event) {
      if (!event.detail.value) {
        this.form.startDate = null;
        this.v$.form.startDate.$touch();
        return;
      }
      // get date from unix
      this.numericStartDate = event.detail.value * 1000;
      this.form.startDate = new Date(this.numericStartDate);
      this.v$.form.startDate.$touch();
      // get defaults
      if (this.form.deadlineStandard) {
        this.calculateStandardDeadlines();
      }
    },
    disclosureDeadlineChanged(event) {
      if (!event.detail.value) {
        this.form.disclosureDeadline = null;
        this.v$.form.disclosureDeadline.$touch();
        return;
      }
      // get date from unix
      this.numericDisclosureDeadline = event.detail.value * 1000;
      this.form.disclosureDeadline = new Date(this.numericDisclosureDeadline);
      this.v$.form.disclosureDeadline.$touch();
      this.form.reportDeadline = addWorkDaysToUnix(this.numericDisclosureDeadline, 5);
      this.numericReportDeadline = new Date(this.form.reportDeadline).getTime();
      this.v$.form.reportDeadline.$touch();
    },
    reportDeadlineChanged(event) {
      if (!event.detail.value) {
        this.form.reportDeadline = null;
        this.v$.form.reportDeadline.$touch();
        return;
      }
      // get date from unix
      this.numericReportDeadline = event.detail.value * 1000;
      this.form.reportDeadline = new Date(this.numericReportDeadline);
      this.v$.form.reportDeadline.$touch();
    },
  },
  validations() {
    return {
      form: {
        recordDate: {
          requiredSelect: helpers.withParams(
            { field: this.$t('shareholderId.recordDate').toLowerCase() },
            requiredSelect,
          ),
        },
        startDate: {
          requiredSelect: helpers.withParams(
            { field: this.$t('shareholderId.startDate').toLowerCase() },
            requiredSelect,
          ),
          minValue: helpers.withParams(
            {
              field: this.$t('shareholderId.startDate').toLowerCase(),
              value: this.$d(subtractWorkDaysToUnix(this.minimumStartDate.getTime(), 1), 'short'),
            },
            dateShouldNotBeBefore(subtractWorkDaysToUnix(this.minimumStartDate.getTime(), 1)),
          ),
        },
        disclosureDeadline: {
          requiredSelect: helpers.withParams(
            { field: this.$t('shareholderId.disclosureDeadlineDate').toLowerCase() },
            requiredSelect,
          ),
          minValue: helpers.withParams(
            {
              field: this.$t('shareholderId.disclosureDeadlineDate').toLowerCase(),
              value: this.$d(this.minimumDisclosureDeadline, 'short'),
            },
            dateShouldNotBeBefore(this.minimumDisclosureDeadline),
          ),
        },
        reportDeadline: {
          requiredSelect: helpers.withParams(
            { field: this.$t('shareholderId.reportDeadlineDate').toLowerCase() },
            requiredSelect,
          ),
          minValue: helpers.withParams(
            {
              field: this.$t('shareholderId.reportDeadlineDate').toLowerCase(),
              value: this.$d(this.minimumReportDeadline, 'short'),
            },
            dateShouldNotBeBefore(this.minimumReportDeadline),
          ),
        },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.mb_1;
@include lib.mt_1;

</style>
