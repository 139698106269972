import { formatDate } from '@/utils/formatLib';
import i18n from '@/i18n';
import {
  createStatus, formatAddress, formatRespondingIntermediaries,
  getEntityTypeTranslation, getHoldingTypeTranslation, getIdTypeTranslation,
} from '@/utils/serviceHelpers/sidRequestDataHelpers';
import { issuerNameConverter } from '@/utils/modelConverters/baseDataTableConverters';

const unknownName = i18n.global.t('utils.unknownValue', { value: 'name' });
const unknownIdentifier = i18n.global.t('utils.unknownValue', { value: 'identifier' });
const unknown = i18n.global.t('utils.unknown');
const undisclosed = i18n.global.t('utils.undisclosed');

/**
 * Create the formatted SID Requests based of the raw data
 * @name createSidRequestData
 * @param data        the raw SID Request data
 * @returns {Object}  SID Requests, formatted
 */
export function createSidRequestData(data) {
  const sidRequests = Object.keys(data.list)
    .map((key) => ({
      id: data.list[key].sidRequestUuid,
      issuerName: issuerNameConverter(data.list[key]),
      isin: data.list[key].isin || unknownIdentifier,
      threshold: data.list[key].shareThreshold,
      startDate: data.list[key].startDate ? formatDate(data.list[key].startDate) : unknown,
      recordDate: data.list[key].recordDate ? formatDate(data.list[key].recordDate) : unknown,
      disclosureDeadlineDate: data.list[key].disclosureDeadlineDate
        ? data.list[key].disclosureDeadlineDate : unknown,
      reportDeadlineDate: data.list[key].reportDeadlineDate
        ? data.list[key].reportDeadlineDate : unknown,
      status: createStatus(data.list[key].status),
      responseRate: data.list[key].responseRate,
    }));
  return {
    sidRequests,
    totalItems: data.totalElements,
  };
}

/**
 * Create the formatted SID Request details based of raw data
 * @name createSidRequestDetails
 * @param data        the raw SID Request details data
 * @returns {Object}  SID Request Details, formatted
 */
export function createSidRequestDetails(data) {
  return {
    uuid: data.sidRequestUuid,
    id: data.identification,
    csd: data.csdName,
    issuerName: data.issuerName || unknownName,
    isin: data.isin || unknownIdentifier,
    threshold: data.shareThreshold || unknown,
    totalOutstandingShares: data.totalOutstandingShares || unknown,
    totalOutstandingSharesCsd: data.totalOutstandingSharesCsd || undisclosed,
    startDate: data.startDate ? formatDate(data.startDate) : unknown,
    recordDate: data.recordDate ? formatDate(data.recordDate) : unknown,
    disclosureDeadlineDate: data.disclosureDeadlineDate
      ? data.disclosureDeadlineDate : unknown,
    reportDeadlineDate: data.reportDeadlineDate ? data.reportDeadlineDate : unknown,
    scheduledPubDate: data.scheduledPublicationDate ? formatDate(data.scheduledPublicationDate) : '-',
    pubSentDate: data.publicationSentDate ? formatDate(data.publicationSentDate) : '-',
    createdDate: data.createdDateTime ? formatDate(data.createdDateTime) : unknown,
    createdBy: data.createdByName,
    approvalDate: data.approvalDateTime ? formatDate(data.approvalDateTime) : null,
    approvedBy: data.approvedByName || null,
    cancelledDate: data.cancelledDateTime ? formatDate(data.cancelledDateTime) : null,
    cancelledBy: data.cancelledByName || null,
    status: createStatus(data.status),
  };
}

/**
 * Create the formatted SID Request shareholders based of raw data
 * @name createSidRequestShareholders
 * @param data        the raw SID Request shareholder data
 * @returns {Object}  SID Request Shareholders, formatted
 */
export function createSidRequestShareholders(data) {
  const shareholders = Object.keys(data.list)
    .map((key) => ({
      rank: data.list[key].rank,
      disclosureResponseId: data.list[key].disclosureResponseId,
      entityType: getEntityTypeTranslation(data.list[key].entityType),
      holdingType: getHoldingTypeTranslation(data.list[key].shareholdingType),
      identifications: data.list[key].identifications ? data.list[key].identifications.join(', ') : unknown,
      names: data.list[key].names ? data.list[key].names.join(', ') : unknownName,
      country: data.list[key].countries ? data.list[key].countries[0] : 'null',
      quantityUnit: data.list[key].quantityUnit,
      quantityFaceAmount: data.list[key].quantityFaceAmount,
      quantity: (data.list[key].quantityUnit === 0)
        ? data.list[key].quantityFaceAmount : data.list[key].quantityUnit,
    }));
  return {
    shareholders,
    totalItems: data.totalElements,
  };
}

/**
 * Create the formatted SID Request shareholders based of raw data
 * @name createSidRequestShareholders
 * @param data        the raw SID Request shareholder data
 * @returns {Object}  SID Request Shareholders, formatted
 */
export function createDisclosureDetailsData(data) {
  return {
    holderName: data.holderName,
    entityType: getEntityTypeTranslation(data.entityType),
    identificationType: getIdTypeTranslation(data.identificationType),
    holderIdentifier: data.holderIdentifier,
    email: data.email === 'UNDISCLOSED' ? undisclosed : data.email,
    securities: data.securities,
    amount: data.securities,
    address: formatAddress(data.address),
    respondingIntermediaries: formatRespondingIntermediaries(data.respondingIntermediaries),
  };
}
