<template>
  <div>
    <section
      id="buttons-tile"
      :aria-label="$t('shareholderId.sidDetailsButtonsTile')"
    >
      <base-tile
        id="title-tile"
        class="p-3 mb-2"
      >
        <div class="header-tile title">
          <base-typology
            tag="h3"
            variant="margin-0"
          >
            {{ $t('gen.details') }}
          </base-typology>
          <div v-if="getButton()">
            <base-button
              id="sid-request-button"
              icon="sy_form_valid"
              size="3"
              label="remove row from table"
              :text="getButton().buttonText"
              style-type="primary"
              @click="onClickButton(getButton().onClickValue)"
              @keyup.enter="onClickButton(getButton().onClickValue);"
            />
          </div>
        </div>
      </base-tile>
    </section>
    <div
      v-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
  </div>
  <!--  First tile-->
  <base-tile
    id="first-tile"
    class="p-3 mb-2"
  >
    <div class="main-body">
      <div class="section">
        <div
          id="sidID"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.sidID') }}
          </base-typology>
          <base-typology>
            {{ sidRequest.id }}
          </base-typology>
        </div>
        <div
          id="recordDate"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.recordDate') }}
          </base-typology>
          <base-typology>
            {{ sidRequest.recordDate }}
          </base-typology>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div
          id="status"
          style="margin-bottom: 1rem;"
        >
          <base-typology tag="label">
            {{ $t('utils.status') }}
          </base-typology><br>
          <aab-status
            id="sidStatus"
            :status-message="sidRequest.status.statusMessage"
            :style-type="sidRequest.status.styleType"
            has-background
          />
        </div>
        <div
          id="progress"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.responseRate') }}
          </base-typology>
          <div class="typology-container">
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div
          id="lastModified"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('utils.lastModified') }}
          </base-typology>
          <base-typology>
            -
          </base-typology>
        </div>
      </div>
    </div>
  </base-tile>
  <!--  Second tile-->
  <base-tile
    id="second-tile"
    class="p-3 mb-2"
  >
    <div class="main-body">
      <div class="section">
        <div id="identification">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.identification') }}
          </base-typology>
          <div
            id="issuer"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('issuer.issuer') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.issuerName }}
            </base-typology>
          </div>
          <div
            id="isin"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.isin') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.isin }}
            </base-typology>
          </div>
          <div
            id="csd"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.csdShort') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.csd }}
            </base-typology>
          </div>
          <div
            id="detailsEmail"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('user.email') }}
            </base-typology>
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div id="shares">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.shares') }}
          </base-typology>
          <div
            id="totalOutstandingShares"
            class="shares-section"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.totalOutstandingShares') }}
            </base-typology>
            <base-typology>
              {{ formatNumber(sidRequest.totalOutstandingShares, "decimal") }}
            </base-typology>
          </div>
          <BaseTileDivider orientation="horizontal"></BaseTileDivider>
          <div class="shares-group">
            <div
              id="threshold"
              class="shares-section"
            >
              <base-typology tag="label">
                {{ $t('shareholderId.threshold') }}
              </base-typology>
            </div>
            <div
              id="percentage"
              class="shares-section"
            >
              <base-typology>
                {{ $t('gen.percentage') }}
              </base-typology>
              <base-typology>
                -
              </base-typology>
            </div>
            <div
              id="quantity"
              class="shares-section"
            >
              <base-typology>
                {{ $t('gen.quantity') }}
              </base-typology>
              <base-typology>
                {{ sidRequest.threshold }}
              </base-typology>
            </div>
          </div>
          <div
            id="amountDisclosedByCsd"
            class="shares-section"
          >
            <base-typology
              tag="label"
              class="margin-bottom"
            >
              {{ $t('shareholderId.amountDisclosedByCsd') }}
            </base-typology>
            <base-typology>
              {{ formatNumber(sidRequest.totalOutstandingSharesCsd, "decimal") }}
            </base-typology>
          </div>
          <div
            id="registeredShares"
            class="shares-section"
          >
            <base-typology
              tag="label"
              class="margin-bottom"
            >
              {{ $t('shareholderId.registeredShares') }}
            </base-typology>
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div id="timeline">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.timeline') }}
          </base-typology>
          <div
            id="recordDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.recordDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.recordDate }}
            </base-typology>
          </div>
          <div
            id="creationDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.creationDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.createdDate }}
            </base-typology>
            <base-typology variant="small">
              {{ $t('gen.by') }} {{ sidRequest.createdBy }}
            </base-typology>
          </div>
          <div
            id="startDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.startDate') }}
              <aab-tooltip :tooltip-text="$t('shareholderId.startDateTooltip')">
                <base-icon
                  slot="tooltip-control"
                  icon-name="sy_others_info"
                  size="2"
                  class="icon"
                  :label="''"
                />
              </aab-tooltip>
            </base-typology>
            <base-typology>
              {{ sidRequest.startDate }}
            </base-typology>
          </div>
          <div
            id="disclosureDeadline"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.disclosureDeadlineDate') }}
            </base-typology>
            <base-typology>
              {{ formatDate(sidRequest.disclosureDeadlineDate) }}
              <aab-status
                v-if="disclosureDeadlineDateWarning?.inFutureRange"
                :status-message="disclosureDeadlineDateWarning.text"
                class="warning-status"
                style-type="warning"
                has-icon="true"
              />
            </base-typology>
          </div>
          <div
            id="reportDeadline"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.reportDeadlineDate') }}
            </base-typology>
            <base-typology>
              {{ formatDate(sidRequest.reportDeadlineDate) }}
              <aab-status
                v-if="reportDeadlineDateWarning?.inFutureRange"
                :status-message="reportDeadlineDateWarning.text"
                class="warning-status"
                style-type="warning"
                has-icon="true"
              />
            </base-typology>
          </div>
        </div>
      </div>
    </div>
  </base-tile>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import {
  formatDate,
  formatDateCountdown,
  formatNumber,
} from '@/utils/formatLib';
import { setSidRequestStatus } from '@/services/sidService';
import { generalStatus } from '@/utils/reference/globalConstants';
import BaseTileDivider from '@/components/base/BaseTileDivider.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'RequestDetails',
  components: {
    BaseButton,
    BaseIcon,
    BaseTileDivider,
    BaseTypology,
    BaseTile,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    sidRequest() {
      return this.sidRequestStore.getSidRequest;
    },
    disclosureDeadlineDateWarning() {
      return this.dateWarning(this.sidRequest.disclosureDeadlineDate);
    },
    reportDeadlineDateWarning() {
      return this.dateWarning(this.sidRequest.reportDeadlineDate);
    },
  },
  methods: {
    formatDate,
    formatNumber,
    getButton() {
      const buttonInfo = 'utils.sidRequestButton';
      if (this.sidRequest.status.value === generalStatus.agentApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.agent')),
          onClickValue: generalStatus.approved,
        };
      }
      if (this.sidRequest.status.value === generalStatus.issuerApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.issuer')),
          onClickValue: generalStatus.approved,
        };
      }
      if (this.sidRequest.status.value === generalStatus.inProgress) {
        return {
          buttonText: this.$t(buttonInfo.concat('.complete')),
          onClickValue: generalStatus.completed,
        };
      }
      return null;
    },
    dateWarning(date) {
      if (date !== this.$t('utils.unknown')) {
        return formatDateCountdown(
          date,
          2,
        );
      }
      return null;
    },
    async refreshPage(newSidRequestData) {
      await this.sidRequestStore.setSidRequestDetails(newSidRequestData);
    },
    async onClickButton(status) {
      try {
        const requestResponse = await setSidRequestStatus(
          this.sidRequestStore.getSidUuid,
          status,
          '',
        );
        await this.refreshPage(requestResponse);
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.disclosureApprovalError')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.p_3;

.header-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

.section {
  width:33%;
}

.section h3 {
  margin-bottom: 16px;
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  position: relative;
}

  .shares-section {
    display: flex;
    justify-content: space-between;
  }

.icon {
  vertical-align: middle;
  line-height: 1;
}

.shares-group {
  margin-bottom: 1rem;
}

.shares-group p {
  margin-bottom: 0;
}

.typology-container {
  margin-top:  0;
}

.standard-layout {
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  flex-direction: column;
  margin-bottom: 1rem;
}

.warning-status {
  padding: 2px 8px;
}

.margin-bottom {
  margin-bottom: 1rem;
}

</style>
