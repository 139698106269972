export default {
  "title": {
    "long": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Welcome to the ", _linked("title.short", undefined, _type)])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Broking Portal"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten password?"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in a valid email and password."])},
    "authenticationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid credentials"])},
    "activateAccount": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set your password to activate your account."])}
    },
    "errors": {
      "passwordRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please adhere to the following requirements:"])},
      "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error activating your account"])}
    }
  },
  "gen": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of "])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" by "])},
    "account": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Account"]), _normalize(["Accounts"])])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("value"))])},
    "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Sub)total"])},
    "missingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing amount"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
    "asap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.S.A.P."])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal person"])},
    "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural person"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "inMillions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(in millions)"])},
    "identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
    "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "securities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities"])},
    "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
    "request": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Request"]), _normalize(["Requests"])])},
    "minimumValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum ", _interpolate(_named("value"))])}
  },
  "issuer": {
    "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
    "errors": {
      "getIssuers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of issuers."])},
      "getIsins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of ISINs."])}
    }
  },
  "csd": {
    "errors": {
      "getCsds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of central securities depositories."])}
    }
  },
  "shareholderId": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareholder ID"])},
    "listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareholder ID requests"])},
    "createSidRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create SID request"])},
    "newRequestLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New shareholder identification request"])},
    "sidID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SID ID"])},
    "isin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN"])},
    "csd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central securities depository"])},
    "csdShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSD"])},
    "nominee": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nominee"]), _normalize(["Nominees"])])},
    "response": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Response"]), _normalize(["Responses"])])},
    "responseDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response details"])},
    "sidDetailsButtonsTile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response details button tile"])},
    "matchingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching details"])},
    "beneficiary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary"])},
    "onOwnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On own account"])},
    "recordDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record date"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "disclosureDeadlineDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclosure deadline"])},
    "reportDeadlineDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report deadline"])},
    "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
    "approvalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval date"])},
    "cancelledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled date"])},
    "actionByName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " by ", _interpolate(_named("name")), " "])},
    "scheduledPubDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled publication date"])},
    "pubSentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication sent date"])},
    "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold"])},
    "calThreshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The calculated threshold is ", _interpolate(_named("value")), " shares"])},
    "outstandingShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding shares"])},
    "totalOutstandingShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total outstanding shares"])},
    "outstandingSharesCsd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding shares via CSD"])},
    "amountDisclosedByCsd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount disclosed by CSD"])},
    "requestSearchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN/issuer name"])},
    "nomineeSearchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nominee LEI, nominee BIC, nominee name or response ID"])},
    "registeredShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered shares"])},
    "responseId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response ID"])},
    "responseRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response rate"])},
    "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response date"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match quality"])},
    "qualityDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match details"])},
    "accountServicer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account servicer"])},
    "accountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account manager"])},
    "accountServicerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account servicer name"])},
    "accountServicerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account servicer ID"])},
    "responder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
    "responderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder name"])},
    "responderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder ID"])},
    "reportCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report creator"])},
    "matchingSearchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder name, responder ID, account servicer name, account servicer ID and account number"])},
    "intermediary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediary"])},
    "disclosedAccountServicer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclosed by account servicer"])},
    "disclosedResponder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclosed by responder"])},
    "disclosureEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclosure email was sent successfully"])},
    "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
    "newRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
    "lastResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last response"])},
    "manualMatching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual matching"])},
    "findMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find match"])},
    "findAMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a match"])},
    "unmatchedValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unmatched ", _interpolate(_named("value"))])},
    "qualityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This shows the quality of the match between the nominee disclosed by the account servicer and the related response."])},
    "tableAggNominees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains data of a single aggregate nominee."])},
    "tableHolderItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains data of a single identified holder."])},
    "tableRespondingIntermediaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the intermediaries that disclosed the holder."])},
    "tableNomineeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the individual accounts provided by account servicers."])},
    "tableMatchingDetailsReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the reference information of the account servicer and the nominated intermediary."])},
    "tableMatchingDetailsIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the matching details of the identifiers sent by the account servicer and the nominated intermediary."])},
    "tableMatchingDetailsAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the matching details of the accounts sent by the account servicer and the nominated intermediary."])},
    "disclosureProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclosure progress"])},
    "holdersGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holders by country"])},
    "holdingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding type"])},
    "OnOwnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On own account"])},
    "securitiesGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities by country"])},
    "securitiesOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities (ownership %)"])},
    "topTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top ten beneficial holders"])},
    "identifiedHolders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified holders"])},
    "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download (Excel)"])},
    "downloadingReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading, please wait..."])},
    "holderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder details"])},
    "respondingIntermediaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responding intermediaries"])},
    "accountsHoldings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts and holdings"])},
    "thirdParties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third parties"])},
    "securitiesAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities account number"])},
    "initialDateOfShareholding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial date of shareholding"])},
    "holderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder name"])},
    "topTenShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top ten total shares (% of total shareholding balance)"])},
    "noRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no shareholder ID requests for this selection criteria"])},
    "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity type"])},
    "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
    "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder"])},
    "ownershipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership type"])},
    "investorType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor type"])},
    "entityTypeLabel": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The shareholder entity type is ", _linked("gen.", "lower", _type), _interpolate(_named("entityType"))])},
    "noResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no ", _interpolate(_named("data")), " for this selection criteria"])},
    "noShareholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no holders identified yet"])},
    "noNomineeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accounts identified yet for this nominee"])},
    "manualMatchCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual matches created"])},
    "matchedAmountCategories": {
      "unmatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmatched"])},
      "partialMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial match"])},
      "matched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matched"])}
    },
    "noRecordDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a record date to calculate the first available start date."])},
    "noStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a start date to calculate the deadlines."])},
    "possibleStartDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The start date is ", _interpolate(_named("field")), "."])},
    "deadlines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Disclosure deadline: ", _interpolate(_named("disclosureDeadline")), " (5 working days). Report deadline: ", _interpolate(_named("reportDeadline")), " (10 working days)."])},
    "startDateTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date may change due to the approval process."])},
    "draftValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an issuer and an ISIN to save as draft"])},
    "submitValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete all fields before submitting"])},
    "errors": {
      "getSidRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the SID requests."])},
      "getSidRequestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the details of the SID request."])},
      "getSidRequestShareholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the shareholders of the SID request."])},
      "getDisclosureProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the disclosure progress of the SID request."])},
      "getDisclosureDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the disclosure details of the shareholder."])},
      "getDisclosuresPerCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the disclosures per country."])},
      "getAggregatedNominees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the nominees of the SID request."])},
      "getAccountLevelNominees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to retrieve the accounts of the nominee."])},
      "getMatchingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to retrieve the matching details for this nominee account."])},
      "getResponseAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to retrieve the unmatched responses for this SID request."])},
      "getNomineeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to retrieve the unmatched nominees for this SID request."])},
      "manualMatchCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error creating the manual match"])},
      "disclosureEmailPreviewError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error getting the email preview."])},
      "disclosureEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error sending the email."])},
      "disclosureApprovalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating the status of the sid request."])},
      "submitSidRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error submitting the SID request."])},
      "saveDraftSidRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error saving the SID request as draft."])}
    }
  },
  "idTypes": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "alien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alien registration number"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "clientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client identification"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver license number"])},
    "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card number"])},
    "lei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEI"])},
    "nationalRegistrationId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National registration identification number"])},
    "nationalRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National registration number"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport number"])},
    "propId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietary number"])},
    "socialSec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax identification number"])}
  },
  "partyRoleTypes": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal guardian"])},
    "dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributor"])},
    "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision maker"])}
  },
  "userManagement": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
    "userOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of users"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no users for the compliance tool"])},
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a user"])},
    "errors": {
      "getUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error getting the users."])},
      "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error adding the user."])},
      "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error deleting the user."])}
    }
  },
  "account": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "myAccount": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])}
    },
    "changePassword": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating your password. Please check that your current password is correct."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully updated your password."])}
    },
    "errors": {
      "getUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred retrieving your details."])}
    }
  },
  "user": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    "launcherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launcher ID"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "username": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Username ( ", _linked("user.launcherId", undefined, _type), " )"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "postalBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.O. box"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "faxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax number"])},
    "role": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Role"]), _normalize(["Roles"])])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])}
  },
  "utils": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "submitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting"])},
    "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])},
    "savingAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving as draft"])},
    "unsavedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes"])},
    "unsavedChangesLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes made will not be saved if you continue."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "closeForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close form"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "toCompanyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all companies"])},
    "toUserList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all users"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you wish to delete ", _interpolate(_named("item"))])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "unknownValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unknown ", _interpolate(_named("value"))])},
    "undisclosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undisclosed"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search on ", _interpolate(_named("value"))])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])},
    "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
    "clearSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear search"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference information"])},
    "identifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiers"])},
    "subMenuItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub menu item"])},
    "chevronIndicating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, linked: _linked, type: _type } = ctx;return _normalize(["Chevron indicating ", _linked(_interpolate(_named("value")), "lower", _type)])},
    "clickLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to follow link"])},
    "clickOpenAccordion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to open accordion"])},
    "clickCloseAccordion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to close accordion"])},
    "productIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product icon"])},
    "switchTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Switch to ", _interpolate(_named("value"))])},
    "selectedValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected ", _interpolate(_named("value"))])},
    "nothingSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing selected"])},
    "nothingSelectedInstruction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make a selection before continuing ", _interpolate(_named("value"))])},
    "nothingSelectedNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shows the selection you make in the list below. Nothing selected now."])},
    "reliability": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " reliability"])},
    "unmatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmatched"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "previewSendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview & send email"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
    "lastEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last email sent"])},
    "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
    "formSection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Form section about ", _interpolate(_named("value"))])},
    "setValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set ", _interpolate(_named("field"))])},
    "statuses": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "agentApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting agent approval"])},
      "issuerApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting issuer approval"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "rejectedByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected by agent"])},
      "rejectedByIssuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected by issuer"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    "sidRequestButton": {
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
      "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve on behalf of issuer"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])}
    },
    "tableSorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table contains the headers for the separate items below"])}
  },
  "aria": {
    "minimumValueField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, linked: _linked, type: _type } = ctx;return _normalize(["The input field for the minimum amount of ", _linked(_interpolate(_named("key")), "lower", _type)])},
    "maximumValueField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, linked: _linked, type: _type } = ctx;return _normalize(["The input field for the maximum amount of ", _linked(_interpolate(_named("key")), "lower", _type)])},
    "searchField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, linked: _linked, type: _type } = ctx;return _normalize(["The input field for searching on ", _linked(_interpolate(_named("key")), "lower", _type)])}
  },
  "errors": {
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad request."])},
    "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us at cbp.support", "@", "nl.abnamro.com."])},
    "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This resource already exists."])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
    "unexpectedLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected locale."])}
  },
  "validationErrors": {
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should contain at least ", _interpolate(_named("min")), " characters"])},
    "atLeastOneLowerCaseChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should contain at least one lower case character"])},
    "atLeastOneUpperCaseChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should contain at least one upper case character"])},
    "atLeastOneDigit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should contain at least one digit (0-9)"])},
    "atLeastOneSpecialChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should contain one of the following characters: ", _interpolate(_named("characters"))])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " is required"])},
    "sameAs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " should match ", _interpolate(_named("sameAsField"))])},
    "invalidValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter a valid ", _interpolate(_named("field"))])},
    "selectValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select a ", _interpolate(_named("field"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " may not exceed the ", _interpolate(_named("limitField")), " of ", _interpolate(_named("value"))])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " may not be less than the ", _interpolate(_named("limitField")), " of ", _interpolate(_named("value"))])},
    "dateShouldNotBeBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " cannot be before ", _interpolate(_named("value"))])}
  },
  "disclosureDetails": {
    "holderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder name"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
    "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity type"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
  },
  "days": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due tomorrow"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "daysLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days left"])},
    "daysAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])}
  }
}